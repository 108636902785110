function extractVueFunctions () {
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _vm._m(0)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"frontmatter-markdown"},[_c('h2',[_vm._v("Contact Us")]),_vm._v(" "),_c('hr'),_vm._v(" "),_c('h5',[_vm._v("Publisher")]),_vm._v(" "),_c('address',[_c('a',{attrs:{"href":"https://rimsa.eu/index.php/journal"}},[_c('strong',[_vm._v("Research Institute of Mountain Stockbreeding and Agriculture")])]),_vm._v(" "),_c('br'),_c('em',[_vm._v("Managing Editors of JMAB: Pavlina Raykovska and Polina Hristova")]),_vm._v(" "),_c('br'),_vm._v("281 Vasil Levski Str.\n    "),_c('br'),_vm._v("5600 Troyan\n    "),_c('br'),_vm._v("Lovech District, BULGARIA\n    "),_c('br')]),_vm._v(" "),_c('address',[_vm._v("\n    Tel: "),_c('a',{attrs:{"href":"tel:+359878129895"}},[_vm._v("+359 878 129895")]),_vm._v(" and "),_c('a',{attrs:{"href":"tel:+359878961305"}},[_vm._v("+359 878 961305")]),_c('br'),_vm._v("\n    Email: "),_c('a',{attrs:{"href":"mailto:jma@mail.bg"}},[_vm._v("jma@mail.bg")])]),_vm._v(" "),_c('hr'),_vm._v(" "),_c('h5',[_vm._v("With the support of")]),_vm._v(" "),_c('img',{attrs:{"src":"/images/fni-en.png","alt":"FNI","width":"200"}}),_vm._v(" "),_c('p',[_vm._v("Under contract: "),_c('strong',[_vm._v("КП-06-НП2/10")]),_vm._v(" 02.12.2020"),_c('br'),_vm._v("\nBulgarian research periodicals - 2021")]),_vm._v(" "),_c('hr'),_vm._v(" "),_c('em',[_vm._v("ISSN 1311-0489 (Print);"),_c('br'),_vm._v("ISSN 2367-8364 (Online)")])])}]

return { render: render, staticRenderFns: staticRenderFns }
}
const vueFunctions = extractVueFunctions()

module.exports = { 
      attributes: {"href":"/contacts","lang":"en"},
    
      vue: {
        component: {
          data: function () {
            return {
              templateRender: null
            }
          },
          render: function (createElement) {
            return this.templateRender ? this.templateRender() : createElement("div", "Rendering");
          },
          created: function () {
            this.templateRender = vueFunctions.render;
            this.$options.staticRenderFns = vueFunctions.staticRenderFns;
          }
        }
      },
     }