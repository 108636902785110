function extractVueFunctions () {
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _vm._m(0)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"frontmatter-markdown"},[_c('h1',[_vm._v("Journal of Mountain Agriculture on the Balkans")]),_vm._v(" "),_c('p',[_c('small',{staticClass:"text-muted"},[_vm._v("ISSN 1311-0489 (Print); ISSN 2367-8364 (Online)")])]),_vm._v(" "),_c('hr'),_vm._v(" "),_c('p',[_vm._v("JMAB е научно списание с отворен достъп, което дава възможност да се публикуват и обсъждат значими изследвания в областта на животновъдството, фуражното производство и ливадарството, трайните насаждения, общото земеделие земеделие и преработка на растениевъдна и животновъдна продукция.")]),_vm._v(" "),_c('p')])}]

return { render: render, staticRenderFns: staticRenderFns }
}
const vueFunctions = extractVueFunctions()

module.exports = { 
      attributes: {"href":"/journals/1311-0489","lang":"bg","title":"Journal of Mountain Agriculture on the Balkans","description":"Изследвания, свързани със земеделието и животновъдството в планинските, предпланинските и равнинните райони в България и в чужбина","issn":"1311-0489","image":"/images/cover-1311-0489.jpg","order":1},
    
      vue: {
        component: {
          data: function () {
            return {
              templateRender: null
            }
          },
          render: function (createElement) {
            return this.templateRender ? this.templateRender() : createElement("div", "Rendering");
          },
          created: function () {
            this.templateRender = vueFunctions.render;
            this.$options.staticRenderFns = vueFunctions.staticRenderFns;
          }
        }
      },
     }